/**
 * Parses a cookie string of format foo=bar; baz=boo; to {foo:bar, baz:boo}
 * @param {String} cookie
 */
const parse = (cookie = document.cookie) =>
  cookie
    .split(/;\s/)
    .map(val => val.replace(';', ''))
    .reduce((hashMap, nameValuePair) => {
      // NOTE: some cookie values can contain `=` in their content
      // therefore split('=') can result in an array with length > 2
      // the first item in the array is always the cookie name
      // the rest may need to be re-joined to represent the value
      if (nameValuePair === '') return { ...hashMap };
      const [name, ...rest] = nameValuePair.split('=');
      const value = rest.join('=');
      return { ...hashMap, [name]: value };
    }, {});

export default parse;
