import React from 'react';
import classNames from 'classnames';
import Link from '../ui/link/Link';
import styles from './link.scss';
import dispatchMonetateQEvent from '../../utils/monetate/monetateEventDispatcher';
import { notifyNavAnalytics } from '../navigation/notify-analytics';

const dispatchEventItemsList = [
  'Insurance',
  'Partnership Credit Card',
  'Track order',
];

const HeaderLink = props => {
  const clickHandler = e => {
    if (props.isNewPreNavVariantActive === 'Control') {
      const targetText = e.target.innerHTML;

      if (!dispatchEventItemsList.includes(targetText)) return;

      // Conditional formatting for "Track order"
      const eventKey =
        targetText === 'Track order'
          ? 'prenav-menu-click.track-your-order'
          : `prenav-menu-click.${targetText.replace(/ /g, '-').toLowerCase()}`;

      dispatchMonetateQEvent(eventKey);
      notifyNavAnalytics('pn', targetText);
    }
  };

  return (
    <Link
      {...props}
      itemClassName={styles.headerLinkItem}
      anchorClassName={classNames(styles.headerLink)}
      handler={clickHandler}
    />
  );
};

export default HeaderLink;
