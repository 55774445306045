import React, { createContext, useState } from 'react';

const initialState = {
  user: {
    signedIn: false,
    greeting: '',
    basket: { items: 0 },
    shopperId: '',
  },
};

export const UserContext = createContext({ userState: initialState });

export const UserContextProvider = props => {
  const [userState, updateUserState] = useState(initialState);

  return (
    <UserContext.Provider value={{ userState, updateUserState }}>
      {props.children}
    </UserContext.Provider>
  );
};
