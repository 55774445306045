import fetch from 'node-fetch';
import createConfig from '../../config';

const getNavigation = async ({
  id,
  depth = 3,
  subset = 'MOBILE_WEB,FLYOUT',
  queryParams = '',
} = {}) => {
  const { urls } = createConfig();
  const idUrl = id ? `/${id}` : '';
  const query = `depth=${depth}&subset=${subset}`;
  const params = queryParams
    ? `${queryParams.replace(/^\?/, '')}&${query}`
    : `${query}`;
  const uri = `${urls.base}/header/api/navigation${idUrl}?${params}`;
  const res = await fetch(uri, { credentials: 'include' });
  const json = await res.json();

  if (res.status !== 200) {
    return { error: json };
  }
  return json;
};

export default getNavigation;
