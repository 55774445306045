import React from 'react';
import ESIInclude from '@JohnLewisPartnership/jl-esi-components';
import { ThemeProvider } from 'jl-design-system/contexts/Theme';
import HeaderWrapper from '../components/header-wrapper/HeaderWrapper';
import EnvVarsFeaturesContext from './EnvVarsFeaturesContext';
import { UserContextProvider } from '../scaffold/UserContext';
import { AppDownloadBannerProvider } from '../context/AppDownloadBannerContext';
import AppDownloadBanner from '../components/app-download-banner/AppDownloadBanner';

class HeaderApp extends React.Component {
  constructor(props) {
    super();
    const {
      envVars = {},
      features = {},
      categories = {},
      headerClientConfigQueryParameters = {},
      query = {},
      notificationBannerFolder = '',
      appDownloadBanner = '',
      preNavRedesign = '',
      storeFinder = '',
      fallbackMenu = '',
      columnHighlighted = '',
      mobileTabs = '',
      hasExperiencesEsi = '',
    } = props.initialState;
    this.envVars = envVars;
    this.features = features;
    this.categories = categories;
    this.headerClientConfigQueryParameters = headerClientConfigQueryParameters;
    this.query = query;
    this.notificationBannerFolder = notificationBannerFolder;
    this.appDownloadBanner = appDownloadBanner;
    this.preNavRedesign = preNavRedesign;
    this.storeFinder = storeFinder;
    this.fallbackMenu = fallbackMenu;
    this.columnHighlighted = columnHighlighted;
    this.mobileTabs = mobileTabs;
    this.hasExperiencesEsi = hasExperiencesEsi;
  }

  render() {
    return (
      <div>
        {this.hasExperiencesEsi ? (
          <ESIInclude
            id="experimentation-esi"
            src="/esi/experimentation/page-load-experiments"
            data-testid="experimentation-esi"
          />
        ) : (
          ''
        )}
        <UserContextProvider>
          <EnvVarsFeaturesContext.Provider
            value={{
              envVars: this.envVars,
              features: this.features,
              categories: this.categories,
              headerClientConfigQueryParameters: this
                .headerClientConfigQueryParameters,
              query: this.query,
              appDownloadBanner: this.appDownloadBanner,
              preNavRedesign: this.preNavRedesign,
              storeFinder: this.storeFinder,
              fallbackMenu: this.fallbackMenu,
              columnHighlighted: this.columnHighlighted,
              mobileTabs: this.mobileTabs,
            }}
          >
            <AppDownloadBannerProvider
              initialValue={{ appDownloadBanner: this.appDownloadBanner }}
            >
              {this.appDownloadBanner && <AppDownloadBanner />}
              <ThemeProvider>
                <HeaderWrapper features={this.features} />
              </ThemeProvider>
            </AppDownloadBannerProvider>
            {this.notificationBannerFolder ? (
              <ESIInclude
                id="header-notification-banner"
                src={`/${this.envVars.bannerPath}/${this.notificationBannerFolder}/index.html`}
                data-testid="header-notification-banner"
              />
            ) : (
              ''
            )}
          </EnvVarsFeaturesContext.Provider>
        </UserContextProvider>
      </div>
    );
  }
}

HeaderApp.displayName = 'HeaderApp';

export default HeaderApp;
