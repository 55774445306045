/**
 * Extracts the total number of items in basket as a number from cookies
 */
const getBasketItems = cachedBasket => {
  const totalNumberOfItems = /\^totalNumberOfItems=(\d+)$/;
  const matches = cachedBasket.match(totalNumberOfItems);
  const items = matches ? matches[1] : 0;
  return Number(items);
};

export default getBasketItems;
