import { defaultFeatures } from '../features';

const fetchApi = async (url, options, fallback = {}) => {
  try {
    const response = await window.fetch(url, options);
    return await response.json();
  } catch (err) {
    console.error(`Header: error fetching initial data from ${url}: ${err}`);
    return fallback;
  }
};

const getYesterday = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return date.toISOString().slice(0, 10);
};

const fetchFallbackCategories = async () => {
  const cacheFilename = `/header-ui-assets/cache/fallback-cache-${getYesterday()}.json`;
  const response = await fetchApi(cacheFilename);
  return response.child ? response : { child: [] };
};

const getInitialData = async (isDesktop, params = new URLSearchParams()) => {
  const stateUrl = '/header/api/state';
  const configUrl = '/header/api/config';

  const stateResponse = await fetchApi(
    stateUrl,
    {
      headers: { 'Cache-Control': 'no-cache, no-store' },
    },
    defaultFeatures
  );

  const queryParams = stateResponse?.headerClientConfigQueryParameters;

  const configUrlParams = new URLSearchParams(queryParams);

  if (isDesktop) {
    configUrlParams.append('isdesktop', true);
  }

  const date = params.get('date');
  if (date) {
    configUrlParams.append('date', date);
    const time = params.get('time');
    if (time) {
      configUrlParams.append('time', time);
    }
  }
  const encodedParams = configUrlParams.toString();
  const url = `${configUrl}${encodedParams.length ? `?${encodedParams}` : ''}`;

  const configResponse = await fetchApi(url);

  const categories =
    configResponse.categories || (await fetchFallbackCategories());

  return {
    ...configResponse,
    ...stateResponse,
    categories,
  };
};

export default getInitialData;
