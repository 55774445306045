const offersCategoryDesktop = name => name && name === 'Sale & Offers';

export const saleOffersCategoryDesktop = name =>
  !!(
    name &&
    (name.toLowerCase().includes('offers') ||
      name.toLowerCase().includes('deals'))
  );

export const blackFridayCategory = name =>
  name && name.toLowerCase().includes('black friday');

export default offersCategoryDesktop;
