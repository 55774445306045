import { isDesktop } from '../../utils/device/identifyDevice';

export const notifySearchTerm = (term = '') => {
  if (!term) return;
  document.body.dispatchEvent(
    new CustomEvent('dataStateChange', {
      detail: {
        action: 'global.searchInteraction',
        searchData: {
          type: 'typed search',
          term,
        },
      },
    })
  );
};

export const notifyNavAnalytics = (type = '', navigation = '') => {
  document.body.dispatchEvent(
    new CustomEvent('dataStateChange', {
      detail: {
        action: 'jl.navTracking',
        type,
        navigation,
      },
    })
  );
};

export default (navigationPath, prefix = '') => {
  document.body.dispatchEvent(
    new CustomEvent('dataStateChange', {
      detail: {
        action: 'jl.navTracking',
        type: `${prefix && `${prefix}|`}${isDesktop() ? 'tf' : 'sf'}`,
        navigation: navigationPath,
      },
    })
  );
};
