export const brandsData = {
  name: 'Brands',
  child: [
    {
      id: '92a1b23c-907b-383e-a5b9-eba3a25f1111',
      contentId: '300000007366',
      name: 'Top brands',
      headingName: 'top brands',
      type: 'BRANDMENUITEM',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
      child: [
        {
          id: '1',
          contentId: '300000007366',
          name: 'Apple',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/apple/_/N-1z13zz4',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '2',
          contentId: '300000007366',
          name: 'Phase Eight',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/phase-eight/_/N-1z13tm6',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '3',
          contentId: '300000007366',
          name: 'Reiss',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/reiss/_/N-1z13vmd',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '4',
          contentId: '300000007366',
          name: 'CHANEL',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/beauty/chanel/c7000370531',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '5',
          contentId: '300000007366',
          name: 'Ted Baker',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/ted-baker/_/N-1z141gc',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '6',
          contentId: '300000007366',
          name: 'hush',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/hush/_/N-pf0f',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '7',
          contentId: '300000007366',
          name: 'LEGO',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/lego/_/N-1z140g1',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '8',
          contentId: '300000007366',
          name: 'Dyson',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/dyson/_/N-1z140en',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '9',
          contentId: '300000007366',
          name: 'Dior',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/dior/_/N-oix5',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '10',
          contentId: '300000007366',
          name: 'Samsung',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/samsung/_/N-1z13z13',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
      ],
    },
    {
      id: '92a1b23c-907b-383e-a5b9-eba3a25f2222',
      contentId: '300000007366',
      name: 'New brands',
      headingName: 'new brands',
      type: 'BRANDMENUITEM',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
      child: [
        {
          id: '1',
          contentId: '300000007366',
          name: 'Marella',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/marella/_/N-1z0o2db',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '2',
          contentId: '300000007366',
          name: 'Sol de Janeiro',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/sol-de-janeiro/_/N-pifv',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '3',
          contentId: '300000007366',
          name: 'LOEWE',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/loewe/_/N-pxbh',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '4',
          contentId: '300000007366',
          name: 'Numph',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/numph/_/N-1z0h69e',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '5',
          contentId: '300000007366',
          name: 'Cosatto',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/cosatto/_/N-1z13vs8',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '6',
          contentId: '300000007366',
          name: 'Gallery direct',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/gallery-direct/_/N-1z13szd',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '7',
          contentId: '300000007366',
          name: 'Heist',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/heist/_/N-puyw',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '8',
          contentId: '300000007366',
          name: 'OKTO',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/okto/_/N-ptix',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '9',
          contentId: '300000007366',
          name: 'Escentric Molecules',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/escentric-molecules/_/N-px1c',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '10',
          contentId: '300000007366',
          name: 'Flattered',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/flattered/_/N-pwgu',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
      ],
    },
    {
      id: '92a1b23c-907b-383e-a5b9-eba3a25f3333',
      contentId: '300000007366',
      name: 'Trending brands',
      headingName: 'trending brands',
      type: 'BRANDMENUITEM',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
      child: [
        {
          id: '1',
          contentId: '300000007366',
          name: 'Mango',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/mango/_/N-1z13um8',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '2',
          contentId: '300000007366',
          name: 'Ninja',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/ninja/_/N-1z0kqso',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '3',
          contentId: '300000007366',
          name: 'Mint Velvet',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/mint-velvet/_/N-1z13x40',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '4',
          contentId: '300000007366',
          name: 'Charlotte Tilbury',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/charlotte-tilbury/_/N-1z0hzn9',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '5',
          contentId: '300000007366',
          name: 'Le Creuset',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/le-creuset/_/N-1z141fy',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '6',
          contentId: '300000007366',
          name: 'Oura',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/oura/_/N-pqpr',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '7',
          contentId: '300000007366',
          name: 'Joseph Joseph',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/joseph-joseph/_/N-1z140v6',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '8',
          contentId: '300000007366',
          name: 'Joie baby',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/joie-baby/_/N-1z0l7bh',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '9',
          contentId: '300000007366',
          name: 'Ralph Lauren',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/ralph-lauren/_/N-1z140o3',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '10',
          contentId: '300000007366',
          name: 'Sony',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/sony/_/N-1z140ar',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
      ],
    },
    {
      id: '92a1b23c-907b-383e-a5b9-eba3a25f4444',
      contentId: '300000007366',
      name: 'John Lewis brands',
      type: 'BRANDMENUITEM',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
      child: [
        {
          id: '1',
          contentId: '300000007366',
          name: 'John Lewis',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/john-lewis/_/N-1z141il',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '2',
          contentId: '300000007366',
          name: 'John Lewis ANYDAY',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/john-lewis-anyday/_/N-o4yo',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '3',
          contentId: '300000007366',
          name: 'AND/OR',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/brand/and/or/_/N-1z04dn1',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '4',
          contentId: '300000007366',
          name: 'John Lewis Heirloom Collection',
          type: 'HEADER',
          url:
            'https://www.johnlewis.com/brand/john-lewis-heirloom-collection/_/N-1z13v4j',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '5',
          contentId: '300000007366',
          name: 'John Lewis x Sophie Conran',
          type: 'HEADER',
          url:
            'https://www.johnlewis.com/brand/john-lewis-x-sophie-conran/_/N-pw34',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
      ],
    },
  ],
};

export const servicesData = {
  name: 'Services',
  child: [
    {
      id: '92a1b23c-907b-383e-a5b9-eba3a25f5b77',
      contentId: '300000007366',
      name: 'Featured services',
      type: 'MENUITEM',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
      child: [
        {
          id: '1',
          contentId: '300000007366',
          name: `Women's personal styling`,
          type: 'HEADER',
          url: 'https://www.johnlewis.com/our-services/personal-styling',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '2',
          contentId: '300000007366',
          name: `Men's personal styling`,
          type: 'HEADER',
          url: 'https://www.johnlewis.com/our-services/personal-styling-men',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '3',
          contentId: '300000007366',
          name: 'Lingerie fitting',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/our-services/lingerie-advice',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '4',
          contentId: '300000007366',
          name: 'Payment plans',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/our-services/paymentplans',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '5',
          contentId: '300000007366',
          name: 'John Lewis Opticians',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/our-services/opticians',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '6',
          contentId: '300000007366',
          name: 'Home Design Service',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/our-services/home-design-service',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '7',
          contentId: '300000007366',
          name: 'Beauty treatments',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/appointments/beauty/shops',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '8',
          contentId: '300000007366',
          name: 'All Things Baby',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/our-services/nursery-advice',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '9',
          contentId: '300000007366',
          name: 'Home appliance installations & recycling',
          type: 'HEADER',
          url:
            'https://www.johnlewis.com/our-services/home-appliance-installation-and-disposal',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '10',
          contentId: '300000007366',
          name: 'TV installation',
          type: 'HEADER',
          url: 'https://www.johnlewis.com/our-services/tv-installation',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
      ],
    },
    {
      id: '92a1b23c-907b-383e-a5b9-eba3a25f5123',
      contentId: '300000007366',
      name: 'John Lewis Money',
      type: 'MENUITEM',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
      child: [
        {
          id: '1',
          contentId: '300000007366',
          name: 'Partnership Credit Card',
          type: 'HEADER',
          url:
            'https://www.johnlewisfinance.com/credit-cards/partnership-card.html',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '2',
          contentId: '300000007366',
          name: 'Home insurance',
          type: 'HEADER',
          url: 'https://www.johnlewisfinance.com/insurance/home-insurance.html',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '3',
          contentId: '300000007366',
          name: 'Pet insurance',
          type: 'HEADER',
          url: 'https://www.johnlewisfinance.com/insurance/pet-insurance.html',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '4',
          contentId: '300000007366',
          name: 'Car insurance',
          type: 'HEADER',
          url: 'https://www.johnlewisfinance.com/insurance/car-insurance.html',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '5',
          contentId: '300000007366',
          name: 'Travel money',
          type: 'HEADER',
          url: 'https://www.johnlewisfinance.com/currency.html',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '6',
          contentId: '300000007366',
          name: 'Investments',
          type: 'HEADER',
          url: 'https://www.johnlewisfinance.com/investments.html',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
        {
          id: '7',
          contentId: '300000007366',
          name: 'International payments',
          type: 'HEADER',
          url: 'https://www.johnlewisfinance.com/money-transfer.html',
          seoInformation: {
            metaTitle: '',
            metaDescription: '',
            index: true,
            follow: true,
          },
          includedInMenus: ['MOBILE_WEB'],
          isCached: true,
          child: [],
        },
      ],
    },
  ],
};

export const helpData = {
  name: 'Help',
  child: [
    {
      id: '1',
      contentId: '300000007367',
      name: 'Customer services',
      type: 'HEADER',
      url: 'https://www.johnlewis.com/customer-services',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
    },
    {
      id: '2',
      contentId: '300000007368',
      name: 'Track your order',
      type: 'HEADER',
      url: 'https://account.johnlewis.com/track-order',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
    },
    {
      id: '3',
      contentId: '300000007369',
      name: 'Returns and refunds',
      type: 'HEADER',
      url: 'https://www.johnlewis.com/customer-services/returns',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
    },
    {
      id: '4',
      contentId: '300000007370',
      name: 'Delivery and collection',
      type: 'HEADER',
      url: 'https://www.johnlewis.com/customer-services/delivery-information',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
    },
    {
      id: '5',
      contentId: '300000007371',
      name: 'Product support',
      type: 'HEADER',
      url: 'https://productsupport.johnlewis.com/',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
    },
    {
      id: '6',
      contentId: '300000007372',
      name: 'Shop finder',
      type: 'HEADER',
      url: 'https://www.johnlewis.com/our-shops',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
    },
    {
      id: '7',
      contentId: '300000007373',
      name: 'Contact us',
      type: 'HEADER',
      url: 'https://www.johnlewis.com/customer-services/contact-us',
      seoInformation: {
        metaTitle: '',
        metaDescription: '',
        index: true,
        follow: true,
      },
      includedInMenus: ['MOBILE_WEB'],
      isCached: true,
    },
  ],
};
