import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ESIInclude from '@JohnLewisPartnership/jl-esi-components';
import styles from './FlyoutMenu.scss';
import FlyoutMenuItem from '../flyout-menu-item/FlyoutMenuItem';
import ContentBlock from '../content-block/ContentBlock';
import isMonetateGroup from '../../../utils/monetate/isMonetateGroup';
import EnvVarsFeaturesContext from '../../../scaffold/EnvVarsFeaturesContext';
import formatTestId from '../../../utils/test-id/format-testid';
import CloseButton from './CloseButton';
import { saleOffersCategoryDesktop } from '../offers-category-desktop';
import filterMenuData from '../../../utils/menu/filterMenuData';

const FlyoutMenu = ({ contentId, columns, hierarchy, onClose, isTouch }) => {
  const { features } = useContext(EnvVarsFeaturesContext);

  const MAX_COLUMNS = 6;
  const filteredColumns =
    filterMenuData(columns, 'FLYOUT')
      ?.map(column => ({
        ...column,
        child: filterMenuData(column.child, 'FLYOUT'),
      }))
      ?.filter(x => x.child?.length) || [];

  if (filteredColumns.length === 0) {
    return null;
  }

  const totalDoubleWidthColumns = filteredColumns.filter(
    column => column.child?.length > 11
  ).length;

  const columnsToDisplay = filteredColumns.slice(
    0,
    MAX_COLUMNS - totalDoubleWidthColumns
  );

  const displayBanner =
    contentId && !isMonetateGroup(features, 'content-blocks');

  const isGroupedActive = isMonetateGroup(features, 'stacked-categories');

  const isFullWidthNav = isMonetateGroup(features, 'full-width-nav');

  return (
    <div
      className={classNames(styles.flyoutMenu, {
        [styles.flyoutMenu__fullWidth]: isFullWidthNav,
        [styles.flyoutMenu__grouped]: isGroupedActive,
      })}
      data-testid="desktop-flyout-menu"
    >
      <div
        className={styles.flyoutMenuColumns}
        data-testid="desktop-flyout-menu-columns"
      >
        {columnsToDisplay.map((column, i) => (
          <div
            key={column.name}
            className={classNames(styles.flyoutMenu__column, {
              [styles.twoCol]: column.child?.length > 11,
            })}
            data-testid={`desktop-flyout-column-${i + 1}`}
          >
            <h4
              className={classNames(styles.flyoutMenuTitle, {
                [styles.flyoutMenuTitle__grouped]: isGroupedActive,
                [styles.saleOffersLabel]: saleOffersCategoryDesktop(
                  column.name
                ),
              })}
              data-testid={formatTestId(column.name, 'flyout-heading-')}
            >
              {column.name}
            </h4>
            {column.child && (
              <ul>
                {column.child.map(link => {
                  const { name, url } = link;
                  return (
                    <FlyoutMenuItem
                      key={name}
                      label={name}
                      url={url}
                      hierarchy={`${hierarchy}|${column.name}`}
                      isTouch={isTouch}
                      isGroupedActive={isGroupedActive}
                    />
                  );
                })}
              </ul>
            )}
            {column.blocks && (
              <div data-testid="content-blocks">
                {column.blocks?.map(block => (
                  <ContentBlock
                    key={block.name}
                    {...block}
                    hierarchy={`${hierarchy}|${column.name}`}
                    multi={column.blocks.length > 1}
                  />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {displayBanner && (
        <div
          className={classNames(styles.flyoutMenuBanner, {
            [styles.flyoutMenuBanner__fullWidth]: isFullWidthNav,
            [styles.flyoutMenuBanner__grouped]: isGroupedActive,
          })}
          data-testid="navigation-content"
        >
          <ESIInclude
            src={`/content-renderer/navigation/${contentId}?no-hydration=true&no-failure=true&includePolyfills=false&includeCoreJS=false`}
            id="navigation-content"
          />
        </div>
      )}
      {isTouch && <CloseButton onClose={onClose} />}
    </div>
  );
};

const itemShape = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape));

FlyoutMenu.defaultProps = {
  isTouch: false,
  contentId: '',
};

FlyoutMenu.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
  hierarchy: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isTouch: PropTypes.bool,
  contentId: PropTypes.string,
};

export default FlyoutMenu;
