import React from 'react';
import classnames from 'classnames';
import styles from './menu-item.scss';

import dispatchNewEvent from '../../utils/analytics/dispatchEvent';

const MenuItem = ({
  children,
  href,
  hasChildren,
  onClick,
  shopAll,
  className,
  breadcrumb,
}) => {
  const classes = classnames(
    styles.link,
    hasChildren && styles['menu-link--with-children'],
    shopAll && styles.shopAll
  );

  const clickHandler = e => {
    if (!hasChildren && breadcrumb) {
      const navPath = breadcrumb
        .filter(Boolean)
        .map(crumb => crumb.title || crumb)
        .join('|');
      dispatchNewEvent('dataStateChange', {
        action: 'jl.navTracking',
        type: 'sf',
        navigation: `${navPath}|${children}`,
      });
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <li className={classnames(styles.item, className)}>
      <a className={classes} href={href} onClick={clickHandler}>
        {children}
      </a>
    </li>
  );
};
export default MenuItem;
