export default (config, request) => {
  const { authenticationEnabled: queryParamOverride } = request.query;
  const {
    isLoggedIn,
    'x-feature-auth0-AUTHENTICATE': cookieOverride,
  } = request.cookies;

  if (queryParamOverride) return queryParamOverride === 'true'; // for sitespeed.io

  if (isLoggedIn !== 'true') return false;
  if (cookieOverride) return cookieOverride === '1'; // for testing
  return config.authenticationEnabled;
};
