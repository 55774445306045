import React from 'react';
import Link from '../ui/link/Link';
import styles from './skip-link.scss';

const SkipLink = props => {
  const handleKeyboard = e => {
    const { nativeEvent } = e;

    const { href } = nativeEvent.target;
    const keycode = nativeEvent.keyCode;

    if (keycode === 13 || keycode === 32) {
      nativeEvent.preventDefault();
      const hash = href.split('#')[1];
      const targetEl = document.querySelector(`#${hash}`);

      // Webkit requires a timeout in order to shift focus
      window.setTimeout(() => {
        targetEl.scrollIntoView(true);
      }, 0);
    }
  };

  return (
    <Link
      {...props}
      itemClassName={styles.skipLinkItem}
      anchorClassName={styles.skipLink}
      keyboardHandler={handleKeyboard}
    />
  );
};

export default SkipLink;
