import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ContentBlock.scss';
import offersCategory from '../offers-category';
import EnvVarsFeaturesContext from '../../../scaffold/EnvVarsFeaturesContext';
import notifyAnalytics from '../notify-analytics';
import formatTestId from '../../../utils/test-id/format-testid';

const ContentBlock = ({
  src,
  alt,
  name,
  text,
  url,
  size,
  multi,
  className,
  hierarchy,
  rounded,
  highlight,
}) => {
  const {
    envVars: { contentPath },
  } = useContext(EnvVarsFeaturesContext);
  const imageSrc = contentPath ? `${contentPath}/images/${src}` : src;

  const isOffer = highlight || offersCategory(name, 1);
  const calcImageHeight = () => {
    if (size === 'compact') return 68;
    if (size === 'content-spots') return 48;
    if (size === 'content-spots-xl') return 96;

    const imageHeight = multi ? 100 : 125;
    return size === 'large' ? 280 : imageHeight;
  };

  const path = hierarchy ? `${hierarchy}|${name}` : name;

  return (
    <a
      href={url}
      key={name}
      onClick={() => notifyAnalytics(path, 'cb')}
      className={classNames(
        styles.contentBlock,
        {
          [styles.contentBlock__multi]: multi && size !== 'compact',
          [styles.contentBlock__compact]: size === 'compact',
          [styles.contentBlock__contentSpots]: size === 'content-spots',
          [styles.contentBlock__contentSpotsXL]: size === 'content-spots-xl',
          [styles.contentBlock__large]: size === 'large-square',
        },
        className
      )}
      data-testid={formatTestId(name, 'content-block-')}
    >
      <img
        className={classNames({
          [styles.contentBlockImageCompact]: size === 'compact',
          [styles.contentBlockImageContentSpots]: size === 'content-spots',
          [styles.contentBlockImageContentSpotsXL]: size === 'content-spots-xl',
          [styles.contentBlockImage]: size !== 'compact',
          [styles.contentBlockImageRounded]: rounded,
          [styles.contentBlockImageLarge]: size === 'large-square',
        })}
        src={imageSrc}
        alt={alt}
        height={calcImageHeight()}
      />
      {(name || text) && (
        <div
          className={classNames({
            [styles.contentBlockTextCompact]: size === 'compact',
            [styles.contentBlockTextContentSpots]: size === 'content-spots',
            [styles.contentBlockTextContentSpotsXL]:
              size === 'content-spots-xl',
          })}
        >
          {name && (
            <span
              className={classNames(styles.contentBlockTitle, {
                [styles.contentBlockTitle__offer]: isOffer,
                [styles.contentBlockTitle__contentSpots]:
                  size === 'content-spots' || size === 'content-spots-xl',
              })}
            >
              {name}
            </span>
          )}
          {text && <p className={styles.contentBlockText}>{text}</p>}
        </div>
      )}
    </a>
  );
};

ContentBlock.defaultProps = {
  name: '',
  text: '',
  size: 'small',
  multi: false,
  className: '',
  hierarchy: '',
  rounded: false,
  highlight: false,
};

ContentBlock.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf([
    'small',
    'large',
    'compact',
    'large-square',
    'content-spots',
    'content-spots-xl',
  ]),
  multi: PropTypes.bool,
  className: PropTypes.string,
  hierarchy: PropTypes.string,
  rounded: PropTypes.bool,
  highlight: PropTypes.bool,
};

export default ContentBlock;
