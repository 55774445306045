import React, { useRef, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'querystringify';

import styles from './search.scss'; // @TODO tidy these up when removing non-disco search
import EnvVarsFeaturesContext from '../../scaffold/EnvVarsFeaturesContext';
import SearchInput from '../search-input/search-input';
import ImageSearch from '../image-search/image-search';
import { notifySearchTerm } from '../navigation/notify-analytics';
import isMonetateGroup from '../../utils/monetate/isMonetateGroup';
import BrandPromiseCloseButton from '../typeahead-overlay/BrandPromiseCloseButton';

const loadSearchBoxFiles = _callbackFunction => {
  if (typeof window !== 'undefined') {
    const baseJLUrl =
      window.location.host === 'www.johnlewis.com'
        ? 'https://www.johnlewis.com'
        : 'https://www.integration.project4.com';

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = `${baseJLUrl}/search-box-ui/static/index.css`;
    const cssLoaded = new Promise((resolve, reject) => {
      link.addEventListener('load', resolve);
      link.addEventListener('error', reject);
    });
    document.head.appendChild(link);

    const script = document.createElement('script');
    const jsLoaded = new Promise((resolve, reject) => {
      script.addEventListener('load', resolve);
      script.addEventListener('error', reject);
    });
    script.type = 'text/javascript';
    script.src = `${baseJLUrl}/search-box-ui/static/index.js`;
    document.head.appendChild(script);

    window.monetateQ = window.monetateQ || [];
    Promise.all([cssLoaded, jsLoaded])
      .then(() => {
        window.monetateQ.push(['trackEvent', ['typeaheadFilesLoadSuccess']]);
        _callbackFunction();
      })
      .catch(err => {
        window.monetateQ.push(['trackEvent', ['typeaheadFilesLoadError']]);
        console.error('Error loading basic search files ', err);
      });
  } else {
    _callbackFunction();
  }
};

export const submitFormValidation = (id, box) => e => {
  const input = e.target.elements[id];
  if (input.value === '') {
    e.preventDefault();
    window.scrollTo(0, 0);
    box.current.focus();
  } else {
    notifySearchTerm(input.value);
  }
};

const Search = ({
  id,
  hideSearch,
  onFocus,
  isTypeaheadOpen,
  isTabbedNavActive = false,
  onCloseButtonClick,
  onTabOut,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { features } = useContext(EnvVarsFeaturesContext);

  const isImageSearchActive = isMonetateGroup(features, 'image-search');

  const URLQuery =
    typeof window === 'object' && queryString.parse(window.location.search);

  useEffect(() => {
    if (!URLQuery.listHead) {
      setSearchTerm(
        URLQuery['search-term'] || URLQuery.originalSearchTerm || ''
      );
    }
  }, []);

  const results = useRef(null);
  const container = useRef(null);
  const form = useRef(null);
  const box = useRef(null);
  const [loaded, setLoaded] = useState(null);

  const submitForm = submitFormValidation(id, box);

  const resetForm = () => {
    setSearchTerm('');
    window.scrollTo(0, 0);
    box.current.focus();
  };

  const typeaheadResultsClasses = classNames(styles.typeaheadResults, {
    [styles.typeaheadResults__hidden]: !isTypeaheadOpen,
  });

  return (
    <>
      <div
        className={classNames(styles.form, {
          [styles.hide]: hideSearch,
        })}
        data-search-form
        ref={container}
        id="search-container"
      >
        <form
          data-testid="search-form"
          name="search-form"
          action="/search"
          className={classNames(styles.form, {
            [styles.form__expanded]: isTypeaheadOpen,
          })}
          ref={form}
          onSubmit={submitForm}
          onReset={resetForm}
        >
          <label htmlFor={id} className={styles.visuallyHidden}>
            Search
          </label>
          <SearchInput
            id={id}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            loaded={loaded}
            loadSearchBoxFiles={loadSearchBoxFiles}
            setLoaded={setLoaded}
            features={features}
            ref={{
              box,
              form,
              results,
              container,
            }}
            parentOnFocus={onFocus}
            isTypeaheadOpen={isTypeaheadOpen}
            showClear={Boolean(searchTerm.length)}
            isTabbedNavActive={isTabbedNavActive}
          />
        </form>
        {isImageSearchActive && (
          <ImageSearch
            hideSearch={hideSearch}
            isTypeaheadOpen={isTypeaheadOpen}
            showClear={Boolean(searchTerm.length)}
          />
        )}

        <BrandPromiseCloseButton
          isTypeaheadOpen={isTypeaheadOpen}
          onCloseButtonClick={onCloseButtonClick}
          isDesktopCloseButton
          onTabOut={onTabOut}
        />
      </div>
      <div
        ref={results}
        className={typeaheadResultsClasses}
        onBlur={onTabOut}
      />
    </>
  );
};

Search.defaultProps = {
  hideSearch: false,
  onFocus: () => {},
  isTypeaheadOpen: false,
  onTabOut: () => {},
  onCloseButtonClick: () => {},
};

Search.propTypes = {
  id: PropTypes.string.isRequired,
  hideSearch: PropTypes.bool,
  onFocus: PropTypes.func,
  isTypeaheadOpen: PropTypes.bool,
  onCloseButtonClick: PropTypes.func,
  onTabOut: PropTypes.func,
};

export default Search;
