import base64 from 'base-64';

export default request => {
  const monetateExperiencesFromHeaderEncoded =
    request.headers['x-experiences-json'] || null;
  const decode = string => base64.decode(string);

  const monetateExperiences = monetateExperiencesFromHeaderEncoded
    ? JSON.parse(decode(monetateExperiencesFromHeaderEncoded))
    : undefined;

  return monetateExperiences;
};
