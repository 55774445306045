import React from 'react';
import classNames from 'classnames';
import UserProfileIcon from 'jl-design-system/elements/icons-jb/Profile24PxOutlined';
import styles from './account-button.scss';

export const AccountButton = ({
  onClick,
  greeting,
  isTabbedNavActive,
  myAccountUrl,
}) => (
  <a
    href={myAccountUrl || '/my-account'}
    onClick={onClick}
    data-test="account-button"
    data-testid="account-button"
    className={classNames(styles.container, {
      [styles.container__tn]: isTabbedNavActive,
    })}
    aria-label="Go to your account"
  >
    {greeting && (
      <span
        className={classNames(styles.greeting, {
          [styles.greeting__tn]: isTabbedNavActive,
        })}
      >
        {greeting}
      </span>
    )}
    <UserProfileIcon box data-testid="account-button-icon" />
    <span
      className={classNames(styles.label, {
        [styles.label__tn]: isTabbedNavActive,
      })}
    >
      Account
    </span>
  </a>
);

export default AccountButton;
