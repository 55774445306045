import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CrossIcon from 'jl-design-system/elements/icons-jb/Close24PxOutlined';

import styles from './typeahead-overlay.scss';

const BrandPromiseCloseButton = ({
  isTypeaheadOpen,
  onCloseButtonClick,
  isDesktopCloseButton = false,
  onTabOut,
}) => {
  const closeButtonClasses = classNames([styles.bpCloseButton], {
    [styles.bpCloseButton__hidden]: !isTypeaheadOpen,
    [styles.bpCloseButton__desktop]: isDesktopCloseButton,
    [styles.bpCloseButton__mobile]: !isDesktopCloseButton,
  });

  return (
    <button
      data-testid={`close-expanded-search-${
        isDesktopCloseButton ? 'desktop' : 'mobile'
      }`}
      className={closeButtonClasses}
      onClick={onCloseButtonClick}
      onBlur={onTabOut}
    >
      <CrossIcon className={classNames(styles.closeIcon)} />
      <p>Close</p>
    </button>
  );
};

BrandPromiseCloseButton.propTypes = {
  isTypeaheadOpen: PropTypes.bool.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  isDesktopCloseButton: PropTypes.bool.isRequired,
  onTabOut: PropTypes.func,
};

BrandPromiseCloseButton.defaultProps = {
  onTabOut: () => {},
};

export default BrandPromiseCloseButton;
