import getMonetateExperience from './getMonetateExperience';

const getMonetateParams = (features, partialExperienceName, paramName = '') => {
  const parameters =
    getMonetateExperience(features, partialExperienceName)?.parameters || {};
  if (!paramName) return parameters;

  return parameters?.[paramName] || parameters;
};

export default getMonetateParams;
