import extractMonetateData from './server/lib/extract-monetate-data';
import isAuthenticationEnabled from './lib/auth/is-authentication-enabled';
import includesBotHeader from './lib/headers/includesBotHeader';

export const defaultFeatures = {
  features: {
    displaySEOLinks: false,
  },
};

export default (config, request) => {
  // Access and decode the monetate experiences
  const monetateExperiences = extractMonetateData(request) || [];
  const { hideAEM } = request.query;
  const authenticationEnabled = isAuthenticationEnabled(config, request);
  const displaySEOLinks =
    !!config.displaySEOLinks && includesBotHeader(request);

  return {
    peepHeader: config.peepHeader === 'true',
    monetateExperiences,
    hideAEM: hideAEM === 'true',
    authenticationEnabled,
    isBrandPromiseActive: true,
    displaySEOLinks,
  };
};
