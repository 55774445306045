import extractLocalPart from '../email/extract-local-part';
import validateIsEmail from '../email/validateIsEmail';

export const charLimit = 18;

const isGreetingUnderLimit = greeting => greeting.length <= charLimit;

const truncateGreeting = greeting => {
  if (isGreetingUnderLimit(greeting)) {
    return greeting;
  }
  return `${greeting.slice(0, charLimit)}...`;
};

export default greeting => {
  if (!greeting) return '';
  const casedGreeting = validateIsEmail(greeting)
    ? greeting.toLowerCase()
    : greeting;

  if (isGreetingUnderLimit(casedGreeting)) {
    return casedGreeting;
  }
  return truncateGreeting(extractLocalPart(casedGreeting) || casedGreeting);
};
