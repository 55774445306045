import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const Context = createContext();

export const useAppDownloadBannerContext = () => useContext(Context);

export const AppDownloadBannerProvider = ({ children, initialValue }) => {
  const [showAppDownloadBanner, updateAppDownloadBanner] = useState(false);

  useEffect(() => {
    if (
      Cookies.get('JL_App_Download_Banner') !== 'true' &&
      initialValue.appDownloadBanner
    ) {
      updateAppDownloadBanner(true);
    }
  }, [initialValue.appDownloadBanner]);

  const setShowAppDownloadBanner = value => {
    if (initialValue.appDownloadBanner) {
      updateAppDownloadBanner(value);
    }
  };

  return (
    <Context.Provider
      value={{
        showAppDownloadBanner,
        setShowAppDownloadBanner,
      }}
    >
      {children}
    </Context.Provider>
  );
};
