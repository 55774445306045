import React, { useState } from 'react';
import styles from './TabbedBrandsMobileMenuTabs.scss';
import dispatchMonetateQEvent from '../../../utils/monetate/monetateEventDispatcher';

const TabbedBrandsMobileMenuTabs = ({ onActiveTabChange }) => {
  const [activeTab, setActiveTab] = useState('Shop');
  const menuItems = [
    { name: 'Shop' },
    { name: 'Brands' },
    { name: 'Services' },
    { name: 'Help' },
  ];

  const handleTabClick = tab => {
    setActiveTab(tab);
    onActiveTabChange(tab);
    if (tab !== 'Shop') {
      dispatchMonetateQEvent(`navigation.tab.${tab.toLowerCase()}.click`);
    }
  };

  return (
    <div className={styles.tabbedBrandsMobileMenuTabs}>
      <ul>
        {menuItems.map(menuItem => (
          <li
            key={menuItem.name}
            className={menuItem.name === activeTab ? styles.activeTab : ''}
          >
            <button onClick={() => handleTabClick(menuItem.name)}>
              {menuItem.name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabbedBrandsMobileMenuTabs;
