import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PrimaryButton as Button } from 'jl-design-system/elements/button/Button';
import CloseButtonJB from 'jl-design-system/elements/icons-jb/Close24PxOutlined';
import styles from './FlyoutMenu.scss';

const CloseButton = ({ onClose, className }) => {
  const CloseIcon = CloseButtonJB;

  return (
    <Button
      className={classNames(styles.close, className)}
      onClick={onClose}
      data-testid="nav-close-button"
      size="small"
    >
      <CloseIcon box title="Close" />
      <span className={styles.visuallyHidden}>Close</span>
    </Button>
  );
};

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CloseButton;
