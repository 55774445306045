const config = (
  envVars = typeof process !== 'undefined' ? process.env : {},
  isBrowser = !!global.window,
  location = global.window && global.window.location
) => {
  let baseUrl;
  if (isBrowser) {
    const { protocol, hostname, port } = location;
    baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  } else {
    // NOTE: process.env is only available on server-side
    baseUrl = `http://localhost:${envVars.SERVER_PORT}`;
  }

  // generate and return config
  return {
    cacheTTL: envVars.CACHE_TTL,
    correlationIdProp: envVars.CORRELATION_ID_PROP,
    server: {
      port: envVars.SERVER_PORT,
    },
    urls: {
      base: baseUrl,
      navigationUrl: envVars.NAVIGATION_SERVICE,
    },
    peepHeader: envVars.FINDABILITY_PEEP_HEADER,
    enableAPICache: envVars.ENABLE_API_CACHE === 'true',
    enableAPICacheCloning: envVars.ENABLE_API_CACHE_CLONING === 'true',
    cacheLimitInBytes: envVars.CACHE_LIMIT_IN_BYTES,
    spaCriteoURL: envVars.SPA_CRITEO_URL,
    spaCriteoEnabled: envVars.SPA_CRITEO_ENABLED === 'true',
    cookieManagementESIEnabled:
      envVars.COOKIE_MANAGEMENT_ESI_ENABLED === 'true',
    headerMinibasketActive: envVars.HEADER_MINIBASKET_ACTIVE === 'true',
    authenticationEnabled: envVars.AUTHENTICATION_ENABLED === 'true',
    authenticationBaseUrl: envVars.AUTHENTICATION_BASE_URL,
    accountBaseUrl: envVars.ACCOUNT_BASE_URL,
    myAccountUrl: envVars.MY_ACCOUNT_URL,
    enableGzip: envVars.ENABLE_GZIP === 'true',
    monetate: {
      url: envVars.MONETATE_API_URL,
      channel: envVars.MONETATE_CHANNEL,
    },
    hideAemContent: envVars.HIDE_AEM_CONTENT === 'true',
    assetsPath: `${envVars.CLIENT_SIDE_BUCKET_PATH}/static`,
    cachePath: `${envVars.SERVER_SIDE_BUCKET_PATH}/cache`,
    contentPath: `${envVars.SERVER_SIDE_BUCKET_PATH}/content`,
    displaySEOLinks: envVars.DISPLAY_SEO_LINKS === 'true',
    isBrandPromiseActive: true,
    bannerPath: `${envVars.BANNER_BUCKET_PATH}`,
    basketApiUrl: envVars.BASKET_API_URL,
    basketApiClientId: envVars.BASKET_API_CLIENT_ID,
  };
};

export default config;
