import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ESIInclude from '@JohnLewisPartnership/jl-esi-components';
import styles from './GroupedFlyoutMenu.scss';
import FlyoutMenuItem from '../flyout-menu-item/FlyoutMenuItem';
import CloseButton from './CloseButton';
import formatTestId from '../../../utils/test-id/format-testid';
import { saleOffersCategoryDesktop } from '../offers-category-desktop';

const GroupedFlyoutMenu = ({
  columns,
  hierarchy,
  onClose,
  isTouch,
  contentId,
}) => {
  const filteredColumns =
    columns
      ?.filter(column => column.isDisplayedInGlobalNav !== false)
      ?.map(column => ({
        ...column,
        child: column.child?.filter(
          item => item.isDisplayedInGlobalNav !== false
        ),
      })) || [];

  const groupByColumn = items =>
    Array.from({ length: 6 })
      .map((_, index) => items.filter(i => i.column === index + 1))
      .filter(c => c.length);

  const displayBanner = contentId !== '';

  const groups = groupByColumn(filteredColumns);
  return (
    <div
      className={styles.groupedFlyoutMenu}
      data-testid="desktop-flyout-grouped-menu"
    >
      <div className={styles.groupedFlyoutMenuColumns}>
        {groups.map((group, i) => (
          <ul
            key={`column-${i + 1}`}
            className={styles.groupedFlyoutMenu__column}
            data-testid={`desktop-flyout-column-${i + 1}`}
          >
            {group.map(category => {
              const { name: groupHeading, child } = category;

              return (
                <li
                  key={groupHeading}
                  className={styles.groupedFlyoutMenu__group}
                >
                  <h4
                    className={classNames(styles.groupedFlyoutMenuTitle, {
                      [styles.saleOffersLabel]: saleOffersCategoryDesktop(
                        groupHeading
                      ),
                    })}
                    data-testid={formatTestId(groupHeading, 'flyout-heading-')}
                  >
                    {groupHeading}
                  </h4>

                  {child && (
                    <ul className={styles.groupedFlyoutMenu__group__links}>
                      {child.map(link => {
                        const { name: itemName, url: linkURL } = link;
                        return (
                          <FlyoutMenuItem
                            key={itemName}
                            label={itemName}
                            url={linkURL}
                            hierarchy={`${hierarchy}|${groupHeading}`}
                          />
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        ))}
      </div>
      {displayBanner && (
        <div
          className={styles.groupedFlyoutMenuBanner}
          data-testid="navigation-content"
        >
          <ESIInclude
            src={`/content-renderer/navigation/${contentId}?no-hydration=true&no-failure=true&includePolyfills=false&includeCoreJS=false`}
            id="navigation-content"
          />
        </div>
      )}
      {isTouch && <CloseButton onClose={onClose} className={styles.close} />}
    </div>
  );
};

const itemShape = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape));

GroupedFlyoutMenu.defaultProps = {
  isTouch: false,
  contentId: '',
};

GroupedFlyoutMenu.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
  hierarchy: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isTouch: PropTypes.bool,
  contentId: PropTypes.string,
};

export default GroupedFlyoutMenu;
