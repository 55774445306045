import decode from './greeting-decoder';
import getBasketItemsFrom from './basket-items';

/**
 * Extracts and transforms user related cookie values
 */
export default ({
  cached_basket: cachedBasket = '',
  userGreeting,
  shopperId,
  signedIn,
  abwlg,
} = {}) => ({
  user: {
    basket: {
      items: getBasketItemsFrom(cachedBasket),
    },
    greeting: decode(userGreeting),
    signedIn: !!signedIn,
    shopperId,
    guestWishList: !!abwlg,
  },
});
