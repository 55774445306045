const pad = value => {
  const v = value.toString();
  return v.length === 1 && !v.startsWith('0') ? `0${v}` : v;
};

const parseDateTime = (date, time) => {
  const formattedDate = date
    .split('-')
    .map(value => pad(value))
    .reverse()
    .join('-');

  const formattedTime = time?.length === 4 ? time : '0000';

  return `effectiveDate=${formattedDate}&effectiveTime=${formattedTime}&effectiveTimezone=Europe%2FLondon`;
};

const isValidDate = date => /\d{1,2}-\d{1,2}-\d{4}/.test(date);

const appendTimestamp = (url, date, time) => {
  if (isValidDate(date)) {
    const dateTime = parseDateTime(date, time);
    return `${url}${/\?/.test(url) ? '&' : '?'}${dateTime}`;
  }

  return url || '';
};

export const isPreview = host => host?.startsWith('www.preview') || false;

export const readPreviewCookie = cookie => {
  if (cookie?.previewDateTime) {
    const dateTime = cookie?.previewDateTime.split('|');
    return { date: dateTime[0], time: dateTime[1] || '0000' };
  }
  return null;
};

export default appendTimestamp;
