import React, { useEffect, useState, forwardRef } from 'react';
import classNames from 'classnames';
import { PrimaryButton as Button } from 'jl-design-system/elements/button/Button';
import CloseButton from 'jl-design-system/elements/icons-jb/Close32PxOutlined';
import SearchIcon from 'jl-design-system/elements/icons-jb/Search32PxOutlined';
import styles from './search-input.scss';

const SearchInput = forwardRef(
  (
    {
      id,
      searchTerm,
      setSearchTerm,
      loaded,
      loadSearchBoxFiles,
      setLoaded,
      features,
      showClear,
      parentOnFocus,
      isTypeaheadOpen,
      isTabbedNavActive = false,
    },
    ref
  ) => {
    const placeholderDefaultText = 'Search product or brand';
    const [placeholderText, setPlaceholderText] = useState(
      placeholderDefaultText
    );
    const { box, form, container, results } = ref;

    const searchClassNames = classNames(styles.searchInput, {
      [styles.searchInput__active]: isTypeaheadOpen || showClear,
    });

    useEffect(() => {
      if (isTypeaheadOpen) {
        setPlaceholderText();
      } else {
        setPlaceholderText(placeholderDefaultText);
      }
    }, [isTypeaheadOpen]);

    return (
      <div
        className={styles.searchInputContainer}
        data-testid="search-input-container"
      >
        <div
          className={classNames(styles.searchContainer, {
            [styles.searchContainer__active]: isTypeaheadOpen,
            [styles.searchContainer__tn]: isTabbedNavActive,
          })}
          data-testid="search-container"
        >
          <div className={styles.glassIconContainer}>
            <Button type="submit" className={styles.searchBarButton}>
              <SearchIcon color="#141414" />
              <span className={styles.visuallyHidden}>Search</span>
            </Button>
          </div>
          <input
            type="text"
            name="search-term"
            id={id}
            label="Search"
            placeholder={placeholderText}
            maxLength={150}
            autoComplete="off"
            value={searchTerm}
            ref={box}
            onChange={e => {
              setSearchTerm(e.target.value);
            }}
            className={searchClassNames}
            onFocus={() => {
              parentOnFocus();
              if (!loaded) {
                loadSearchBoxFiles(() => {
                  // eslint-disable-next-line no-unused-expressions
                  typeof window !== 'undefined' &&
                    window.SearchBoxUI.init(
                      results.current,
                      container.current,
                      form.current,
                      box.current,
                      features.monetateExperiences,
                      true,
                      {}
                    );
                });
              }
              setLoaded(true);
            }}
          />
          <div className={styles.closeIconContainer}>
            <Button
              type="reset"
              className={classNames(styles.searchBarButton, {
                [styles.hide]: !showClear,
              })}
            >
              <CloseButton color="#141414" />
              <span className={styles.visuallyHidden}>Clear</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

SearchInput.displayName = 'SearchInput';
export default SearchInput;
