/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './typeahead-overlay.scss';

const BrandPromiseOverlay = ({
  isTypeaheadOpen,
  onClickAway,
  isWhiteTypeaheadActive = false,
}) => {
  const transparentOverlayClasses = classNames(styles.bpTransparentOverlay, {
    [styles.bpTransparentOverlay__hidden]: !isTypeaheadOpen,
  });

  const bpOverlayClasses = classNames(styles.bpOverlay, {
    [styles.bpOverlay__hidden]: !isTypeaheadOpen,
    [styles.bpOverlay__white]: isWhiteTypeaheadActive,
  });

  return (
    <>
      <div
        className={transparentOverlayClasses}
        data-testid="search-transparent-overlay"
        onClick={onClickAway}
      />
      <div className={bpOverlayClasses} data-testid="search-bp-overlay" />
    </>
  );
};

BrandPromiseOverlay.propTypes = {
  isTypeaheadOpen: PropTypes.bool.isRequired,
};

export default BrandPromiseOverlay;
