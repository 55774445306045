import React from 'react';
import dispatchMonetateQEvent from '../../../utils/monetate/monetateEventDispatcher';
import styles from './StoreFinderButton.scss';

const StoreFInderButton = () => {
  const clickHandler = () => {
    dispatchMonetateQEvent('mobile-menu-click.store-finder-button');
  };

  return (
    <div className={styles.storeFinder}>
      <a
        className={styles.storeFinder__button}
        data-testid="nav-store-finder"
        href="/our-shops"
        onClick={clickHandler}
      >
        Find a store
      </a>
    </div>
  );
};

export default StoreFInderButton;
