import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import LoadingSpinner from 'jl-design-system/components/loading-spinner/LoadingSpinner';
import CloseIcon from 'jl-design-system/elements/icons-jb/Close24PxOutlined';
import styles from './image-search.scss';
import parseCookies from '../../../lib/cookie/parser';

const ImageSearch = ({ isTypeaheadOpen, hideSearch, showClear }) => {
  const formEl = useRef(null);
  const [uploading, setUploading] = useState(false);
  const url = `https://search.visenze.com/v1/product/search_by_image?app_key=bff0000f57f0c2d2b8d0c3a5474ef031&placement_id=501&limit=500&score=true&score_min=0.5`;

  const [showTooltip, setShowTooltip] = useState(false);

  const checkFileSize = file => {
    if (file && file.size && file.size < 10000000) {
      return true;
    }
    // eslint-disable-next-line no-alert
    window.alert(
      'Image too large, Make sure your image is not larger than 10mb.'
    );
    return false;
  };

  const postImageToViSense = async file => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error uploading image : ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
      return { fetchError: error };
    }
  };

  const redirectToImageSearchPage = imId => {
    window.location.assign(`/image-search?imageId=${imId}`);
  };

  const checkIfImage = file => {
    const filetype = file?.type.substring(0, 6);
    if (filetype === 'image/') {
      return true;
    }
    // eslint-disable-next-line no-alert
    window.alert(
      'Unsupported image format, accepted formats are .jpg, .jpeg, .jpe, .png, .bmp, .dib, .webp, .pbm, .pmm, .sr, .ras, .tiff, .tif'
    );
    return false;
  };

  /* eslint-disable consistent-return */
  const uploadImage = e => {
    try {
      setUploading(true);
      const file = e.target.files[0];
      if (!file) {
        setUploading(false);
        // eslint-disable-next-line no-alert
        window.alert('Please select an image');
        return false;
      }

      if (checkFileSize(file) && checkIfImage(file)) {
        postImageToViSense(file)
          .then(data => {
            setUploading(false);
            if (data?.fetchError) {
              if (
                !/Load failed/.test(data.fetchError.message) &&
                !/Failed to fetch/.test(data.fetchError.message)
              ) {
                // eslint-disable-next-line no-alert
                window.alert('Error uploading the image, please try again');
              }
            } else if (data?.im_id) {
              redirectToImageSearchPage(data.im_id);
            } else {
              // eslint-disable-next-line no-alert
              window.alert(
                'Unable to correctly identify the image, please try again'
              );
            }
          })
          .catch(() => {
            setUploading(false);
            // eslint-disable-next-line no-alert
            window.alert('Error uploading the image, please try again');
          });
      }
    } catch (error) {
      setUploading(false);
      // eslint-disable-next-line no-alert
      window.alert('Something went wrong, There was a technical error.');
    }
  };

  const writeTooltipCookie = () => {
    const domain = window.location?.hostname
      ? `domain=${window.location.hostname.replace('www', '')};`
      : '';
    window.document.cookie = `ImageSearch_Tooltip_Dismissed=true; ${domain} path=/; max-age=2592000;`;
  };

  const readTooltipCookie = () => {
    const dismissed = parseCookies().ImageSearch_Tooltip_Dismissed;
    if (!dismissed) {
      setShowTooltip(true);
    }
  };

  const dismissTooltip = () => {
    setShowTooltip(false);
    writeTooltipCookie();
  };

  const handleClick = () => {
    if (showTooltip) dismissTooltip();
    document.body.dispatchEvent(
      new CustomEvent('dataStateChange', {
        detail: {
          action: 'global.interaction',
          type: 'search',
          subType: 'image search cta',
          additionalInfo: {},
          properties: {},
          products: {},
        },
      })
    );
  };

  useEffect(() => {
    readTooltipCookie();
  }, []);

  return (
    <>
      <form
        data-testid="search-image-container"
        className={classNames(
          styles.imageSearchContainer,
          styles.imageSearchContainer__icon_inside,
          { [styles.hidden]: showClear || hideSearch || isTypeaheadOpen }
        )}
        method="POST"
        action="/image-search"
        encType="multipart/form-data"
        ref={formEl}
      >
        <label
          className={classNames(styles.imageSearchLabel, {
            [styles.imageSearchLabel__uploading]: uploading,
          })}
        >
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={uploadImage}
            data-testid="image-search-input"
            onClick={handleClick}
          />
          {uploading ? (
            <LoadingSpinner />
          ) : (
            <svg
              viewBox="0 0 24 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="22"
              aria-labelledby="cameraTitle cameraDesc"
              role="img"
              className={styles.imageSearchLabel__pointer}
              data-testid="image-search-icon"
            >
              <title id="cameraTitle">Search by Image</title>
              <desc id="cameraDesc">
                This will allow you to search by a image or photo
              </desc>
              <path
                d="M20 4.75H23.25V19.9167H0.75V4.75H4H4.46353L4.67082 4.33541L6.46353 0.75H17.5365L19.3292 4.33541L19.5365 4.75H20ZM19.6667 10.0833C20.6332 10.0833 21.4167 9.29983 21.4167 8.33333C21.4167 7.36683 20.6332 6.58333 19.6667 6.58333C18.7002 6.58333 17.9167 7.36683 17.9167 8.33333C17.9167 9.29983 18.7002 10.0833 19.6667 10.0833ZM12 16.75C15.1756 16.75 17.75 14.1756 17.75 11C17.75 7.82436 15.1756 5.25 12 5.25C8.82436 5.25 6.25 7.82436 6.25 11C6.25 14.1756 8.82436 16.75 12 16.75Z"
                fill="#141414"
                stroke="#141414"
                strokeWidth="1.5"
              />
            </svg>
          )}
        </label>
      </form>
      {showTooltip && (
        <div
          className={classNames(styles.tooltip, {
            [styles.hidden]: showClear || hideSearch || isTypeaheadOpen,
          })}
          data-testid="image-search-tooltip"
        >
          <span>Search with an image</span>
          <button
            onClick={dismissTooltip}
            data-testid="image-search-tooltip-dismiss"
          >
            <CloseIcon box title="Close" />
          </button>
        </div>
      )}
    </>
  );
};

export default ImageSearch;
