const isMonetateGroup = (
  features,
  experienceName,
  splitValue = 'Experiment'
) => {
  const experiences = features?.monetateExperiences || [];
  return experiences.some(exp => {
    const experience = exp.experienceName || exp.key;
    const split = exp.variantLabel || exp.split;
    return (
      experience.indexOf(experienceName) > -1 &&
      (split === splitValue || split.includes(splitValue))
    );
  });
};

export default isMonetateGroup;
