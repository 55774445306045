import React from 'react';
import PropTypes from 'prop-types';

const SEOLinks = ({ categories }) => {
  const mapCategory = category =>
    category?.map(({ name, url, child }) => (
      <React.Fragment key={name}>
        {url && <a href={url}>{name}</a>}
        {child?.length > 0 && mapCategory(child)}
      </React.Fragment>
    )) || [];

  return (
    <div data-testid="navigation-menu-links" style={{ display: 'none' }}>
      {categories?.length > 0 && mapCategory(categories)}
    </div>
  );
};

const itemShape = {
  name: PropTypes.string,
  url: PropTypes.string,
};
itemShape.child = PropTypes.arrayOf(PropTypes.shape(itemShape));

SEOLinks.defaultProps = {
  categories: [],
};

SEOLinks.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape(itemShape)),
};

export default SEOLinks;
