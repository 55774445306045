import React from 'react';
import classnames from 'classnames';
import styles from './prenav-menu.scss';
import dispatchMonetateQEvent from '../../../utils/monetate/monetateEventDispatcher';
import { notifyNavAnalytics } from '../../navigation/notify-analytics';

const insuranceItemsList = ['Car insurance', 'Pet insurance', 'Home insurance'];

const PrenavMenu = ({ menuLinks = [], itemClassName, onClose }) => {
  const clickHandler = e => {
    const targetText = e.target.innerHTML;

    // Conditional formatting for Home/Car/Pet insurance
    const eventName = insuranceItemsList.includes(targetText)
      ? 'insurance'
      : targetText.replace(/ /g, '-').toLowerCase();

    dispatchMonetateQEvent(`prenav-menu-click.${eventName}`);

    notifyNavAnalytics('pn', targetText);
  };

  if (!menuLinks?.length) return null;

  const handleKeyDown = (e, index) => {
    if (e.key === 'Tab' && index === menuLinks.length - 1 && !e.shiftKey) {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <div
      className={itemClassName}
      data-testid="prenav-menu"
      tabIndex="-1"
      onBlur={e => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          onClose();
        }
      }}
    >
      <div className={styles.menuOverlay} data-testid="menuOverlay">
        <ul className={styles.menuOverlayBody}>
          {menuLinks.map((menuLink, index) => (
            <li
              className={classnames(styles.item)}
              tabIndex={index}
              key={menuLink.name}
            >
              <a
                className={styles.link}
                href={menuLink.url}
                onClick={clickHandler}
                onKeyDown={e => handleKeyDown(e, index)}
                {...(menuLink.external && {
                  target: '_blank',
                  rel: 'noopener',
                })}
              >
                {menuLink.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PrenavMenu;
