import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import WishlistIcon from 'jl-design-system/elements/icons-jb/Wishlist24PxOutlined';
// eslint-disable-next-line import/no-named-as-default
import AccountButton from '../account-button/AccountButton';
import AccountMenu from '../account-menu/AccountMenu';
import formatGreeting from '../../utils/user/format-greeting';
import { isDesktop, isTouchDevice } from '../../utils/device/identifyDevice';
import { UserContext } from '../../scaffold/UserContext';
import { getAccountLinks } from '../links';
import { notifyNavAnalytics } from '../navigation/notify-analytics';

import styles from './account-links.scss';
import EnvVarsFeaturesContext from '../../scaffold/EnvVarsFeaturesContext';

const AccountLinks = ({ isTabbedNavActive }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    envVars: { accountBaseUrl, myAccountUrl },
  } = useContext(EnvVarsFeaturesContext);
  const {
    userState: {
      user: { signedIn, greeting, guestWishList },
    },
  } = useContext(UserContext);

  const toggleMenu = e => {
    e.preventDefault();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleOnClickSignedOut = () => {
    if (!isDesktop()) {
      notifyNavAnalytics('mv', 'Account');
    }
  };

  const handleOnClickSignedIn = e => {
    if (!isDesktop()) {
      notifyNavAnalytics('mv', 'Account');
    }
    if (isTouchDevice() && isDesktop()) {
      toggleMenu(e);
    }
  };

  const formattedGreeting = formatGreeting(greeting);
  const displayedGreeting =
    formattedGreeting === '' ? 'My account' : `Hello ${formattedGreeting}`;
  const accountLinks = getAccountLinks(accountBaseUrl, myAccountUrl);

  const wishListUrl =
    guestWishList && !signedIn
      ? `/wish-list/guest`
      : `${accountBaseUrl}/wish-list`;
  return (
    <div className={styles.container}>
      {signedIn ? (
        <div
          className={classNames(styles.menuTrigger, {
            [styles.menuTrigger__tn]: isTabbedNavActive,
          })}
          data-test="menu-triggers"
          data-testid="menu-trigger"
          onMouseEnter={e => !isTouchDevice() && toggleMenu(e)}
          onMouseLeave={e => !isTouchDevice() && toggleMenu(e)}
        >
          <div
            className={classNames(styles.account, {
              [styles.account__tn]: isTabbedNavActive,
            })}
            data-testid="account-button-container"
          >
            <AccountButton
              onClick={handleOnClickSignedIn}
              greeting={displayedGreeting}
              isTabbedNavActive={isTabbedNavActive}
              myAccountUrl={myAccountUrl}
            />
            <AccountMenu isOpen={isMenuOpen} accountLinks={accountLinks} />
          </div>
        </div>
      ) : (
        <>
          <div
            className={classNames(styles.signIn, styles.desktop, {
              [styles.desktop__tn]: isTabbedNavActive,
            })}
            data-testid="account-sign-in-url"
          >
            <a href="/sign-in">Sign in</a>
          </div>
          <AccountButton
            onClick={handleOnClickSignedOut}
            isTabbedNavActive={isTabbedNavActive}
            myAccountUrl={myAccountUrl}
          />
        </>
      )}
      <a
        href={wishListUrl}
        className={classNames(styles.icon, styles.desktop, {
          [styles.desktop__tn]: isTabbedNavActive,
        })}
        data-test="wishlist-button"
        data-testid="wishlist-button"
        aria-label="View your wishlist"
      >
        <WishlistIcon box />
      </a>
    </div>
  );
};

export default AccountLinks;
