import parseCookies from 'lib/cookie/parser';
import dispatchEvent from '../analytics/dispatchEvent';

const MONETATE_COOKIE = 'mt.v';
const MONETATE_IMPRESSION = 'monetate:record:Impressions';
const LOGGING_TEST =
  'DM | DIGI-2367 | navigation-experimental home-and-garden-ia 50-50 2 A-A';

const getExperienceByName = (id, monetateExperiences) => {
  const experiences = monetateExperiences || [];
  return experiences.find(
    experience => experience.experienceName.indexOf(id) > -1
  );
};

const postMonetateImpression = async (
  monetate,
  id,
  monetateExperiences = []
) => {
  const experience = getExperienceByName(id, monetateExperiences);
  if (experience && experience.impressionId) {
    const impressionRecord = {
      channel: monetate.channel,
      monetateId: parseCookies()[MONETATE_COOKIE] || null,
      events: [
        {
          eventType: MONETATE_IMPRESSION,
          impressionIds: [experience.impressionId],
        },
      ],
    };
    if (experience.experienceName === LOGGING_TEST) {
      console.warn(`MANAGED IMPRESSION DEBUG: 
        monetateId: ${parseCookies()[MONETATE_COOKIE] || null}
        impressionId: ${experience.impressionId}, 
        experienceName: ${experience.experienceName}, 
        variantLabel: ${experience.variantLabel}, 
      `);
    }

    try {
      // Monetate postback
      await fetch(monetate.url, {
        method: 'POST',
        body: JSON.stringify(impressionRecord),
      });
      // Omniture postback
      dispatchEvent('dataStateChange', {
        action: 'managedImpression',
        campaigns: [
          {
            id: experience.impressionId,
            key: experience.experienceName,
            reports: [],
            split: experience.variantLabel,
          },
        ],
      });
    } catch (e) {
      console.error(e.message);
    }
  }
};

export default postMonetateImpression;
