export const desktopMediaQuery = () =>
  window.matchMedia('only screen and (min-width: 1024px)');
export const isDesktop = () => desktopMediaQuery().matches;
export const touchMediaQuery = () => window.matchMedia('(pointer: coarse)');
export const isTouchDevice = () => touchMediaQuery().matches;
const tabletPortraitMediaQuery = () =>
  window.matchMedia(
    'only screen and (min-width: 768px) and (max-width: 1023px) and (pointer: coarse)'
  );
export const isTabletPortrait = () => tabletPortraitMediaQuery().matches;

export const addMediaQueryListener = callback => {
  tabletPortraitMediaQuery().addListener(
    e => {
      if (e.matches === false) {
        callback();
      }
    },
    { once: true }
  );
};

export default {
  desktopMediaQuery,
  isDesktop,
  touchMediaQuery,
  isTouchDevice,
  isTabletPortrait,
};
