/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState, useContext } from 'react';

import ResponsiveHeader from '../responsive-header/ResponsiveHeader';

import { SkipLinks } from '../link-list/LinkList';
import { skipLinks } from '../links';
import authenticateUser from '../../../client/authenticate-user';
import Navigation from '../navigation/Navigation';
import styles from './header-wrapper.scss';
import { UserContext } from '../../scaffold/UserContext';
import getUserProfile from '../../../client/getUserProfile';
import { useAppDownloadBannerContext } from '../../context/AppDownloadBannerContext';

const Header = ({ features }) => {
  const [mobileNavShown, setMobileNavShown] = useState(false);

  const { updateUserState } = useContext(UserContext);
  const [componentMounted, updateComponentMounted] = useState(false);
  const { showAppDownloadBanner } = useAppDownloadBannerContext();

  useEffect(() => {
    if (features.authenticationEnabled && !componentMounted) {
      authenticateUser(updateUserState);
    } else if (!features.authenticationEnabled && !componentMounted) {
      const userProfile = getUserProfile();
      updateUserState(userProfile);
    }
    updateComponentMounted(true);
  }, [componentMounted]);

  return (
    <header className={styles.siteWide}>
      <div className={styles.headerContainer}>
        <SkipLinks links={skipLinks} />
        <ResponsiveHeader
          updateMobileNavState={setMobileNavShown}
          showAppDownloadBanner={showAppDownloadBanner}
        />
      </div>
      <Navigation mobileNavShown={mobileNavShown} />
    </header>
  );
};

export default Header;
