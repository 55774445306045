import getAuth0 from './get-auth0';
import getUserProfile from './getUserProfile';

export default async updateUserState => {
  const auth0 = getAuth0();
  if (!auth0) return;

  const token = await auth0.getTokenIfAuthenticated();
  if (!token) return;

  const userProfile = getUserProfile();
  updateUserState(userProfile);
};
