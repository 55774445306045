import React, { useState } from 'react';
import classNames from 'classnames';
import ChevronDown16PxOutlinedIcon from 'jl-design-system/elements/icons-jb/ChevronDown16PxOutlined';
import PrenavMenu from '../ui/prenav-menu/PrenavMenu';
import styles from './prenav-link.scss';
import dispatchMonetateQEvent from '../../utils/monetate/monetateEventDispatcher';
import { notifyNavAnalytics } from '../navigation/notify-analytics';

const PrenavLink = props => {
  const clickHandler = e => {
    dispatchMonetateQEvent(
      `prenav-menu-click.${e.target.innerHTML.replace(/ /g, '-').toLowerCase()}`
    );
    notifyNavAnalytics('pn', e.target.innerHTML);
  };

  const [showmenu, setShowmenu] = useState(false);

  return (
    <li
      className={classNames(styles.headerLinksContainer, {
        [styles.headerLinksContainer__prenav]: props.isNewPreNavVariantActive,
      })}
      data-testid="prenav-redesign-container"
      onMouseEnter={() => setShowmenu(true)}
      onMouseLeave={() => setShowmenu(false)}
    >
      <>
        <a
          key={props.children}
          className={classNames(styles.headerLinkItem, {
            [styles.underlinedHeaderLinkItem]: props.menuLinks.length === 0,
            [styles.headerLinkItem__prenav]: props.isNewPreNavVariantActive,
            [styles.underlinedHeaderLinkItem__prenav]:
              props.menuLinks.length === 0,
          })}
          href={props.url || '#'}
          tabIndex={props.children ? 0 : -1}
          onClick={
            props.menuLinks.length > 0 ? e => e.preventDefault() : clickHandler
          }
          onKeyDown={e => {
            if (
              props.menuLinks.length > 0 &&
              (e.key === 'Enter' || e.key === ' ')
            ) {
              e.preventDefault();
              setShowmenu(prev => !prev);
            }
          }}
          {...(props.menuLinks.length > 0 && {
            'aria-haspopup': 'true',
            'aria-expanded': showmenu,
            'aria-controls': 'prenav-menu',
          })}
        >
          {props.children}
          {props.menuLinks.length > 0 && (
            <ChevronDown16PxOutlinedIcon className={styles.menuChevronIcon} />
          )}
        </a>
        {showmenu && props.menuLinks.length > 0 && (
          <PrenavMenu
            {...props}
            itemClassName={classNames(styles.prenavLinkMenuBox, {
              [styles.prenavLinkMenuBox__prenav]:
                props.isNewPreNavVariantActive,
            })}
            onClose={() => setShowmenu(false)}
          />
        )}
      </>
    </li>
  );
};

export default PrenavLink;
