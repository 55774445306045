import React from 'react';
import InStoreIcon from 'jl-design-system/elements/icons-jb/InStore24PxOutlined';
import styles from './store-finder-icon.scss';

const StoreFinderIcon = () => (
  <a
    className={styles.link}
    href="/our-shops"
    data-test="store-finder-anchor"
    data-testid="store-finder-anchor"
    aria-label="Store finder"
  >
    <InStoreIcon box title="Store Finder" />
    <span className={styles.label} data-testid="store-finder-icon-label">
      Stores
    </span>
  </a>
);

export default StoreFinderIcon;
