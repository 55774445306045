import React from 'react';

const Link = ({
  url,
  external,
  children,
  itemClassName,
  anchorClassName,
  handler,
  keyboardHandler,
}) => (
  <li suppressHydrationWarning className={itemClassName}>
    <a
      className={anchorClassName}
      href={url}
      {...(external && { target: '_blank', rel: 'noopener' })}
      onClick={handler}
      onKeyDown={keyboardHandler}
      suppressHydrationWarning
    >
      {children}
    </a>
  </li>
);

export default Link;
